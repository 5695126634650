import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { formatCurrency, formatPercentage, renderDateAmountTableRows, getToken } from '../../utils';
import TableCard from '../TableCard';
import BreakoutRow from '../BreakoutRow';
import '../InvestorPage.css';

const apiUrl = process.env.REACT_APP_API_URL;

const InvestorProperty = ({ property_name, picture_url }) => {
    const { investor_name } = useParams() || {};
    const [data, setData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = getToken(navigate);

                const response = await axios.get(`${apiUrl}/api/investor_property`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params: { property_name, investor_name },
                });

                setData(response.data);
            } catch (error) {
                if (error.response?.status === 401 || error.response?.status === 422) {
                    navigate('/');
                }
            }
        };

        fetchData();
    }, [investor_name, property_name, navigate]);

    if (!data) return null;

    const { investments_per_month, cashflow_per_month } = data;

    return (
        <Container className="py-5 bg-white">
            <Row className="align-items-stretch mb-4">
                <Col md={4} className="d-flex">
                    <Card className="p-4 mb-4 rounded-3 flex-fill">
                        <Card.Title className="text-font lead fw-bold text-center mt-3">
                            {property_name} Details
                        </Card.Title>
                        <Card.Body className="d-flex flex-column">
                            <div className="d-flex justify-content-center">
                                <Card.Img
                                    src={picture_url}
                                    className="mb-4"
                                    style={{ width: '200px', height: '200px', objectFit: 'cover' }}
                                    alt=""
                                />
                            </div>
                            <BreakoutRow title="Investments" text={formatCurrency(data.total_investments)} />
                            <BreakoutRow title="Income" text={formatCurrency(data.total_income)} />
                            <BreakoutRow title="ROI" text={formatPercentage(data.total_roi)} />
                            <BreakoutRow title="Notes" text={data.notes} stackTextBelow />
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4} className="d-flex">
                    <TableCard title="Property Investment History" headers={['Date', 'Amount']}
                        padding="p-4" list={renderDateAmountTableRows(investments_per_month, "No investments available.")} />
                </Col>
                <Col md={4} className="d-flex">
                    <TableCard title="Property Income History" headers={['Date', 'Amount']}
                        padding="p-4" list={renderDateAmountTableRows(cashflow_per_month, "No income available.")} />
                </Col>
            </Row>
        </Container>
    );
};

export default InvestorProperty;
