import { useNavigate } from 'react-router-dom';
import TableCard from './TableCard';

const ClickableListTable = ({ list, list_name, path = null, additionalParam = null, onRowClick = null }) => {
    const navigate = useNavigate();

    const handleRowClick = (entry) => {
        if (onRowClick) {
            onRowClick(entry)
        }
        else {
            if (additionalParam) {
                navigate(`${path}/${entry}`, { state: { additionalParam } });
            } else {
                navigate(`${path}/${entry}`);
            }
        }
    };

    const hasEntries = list && list.length > 0;

    return (
        <TableCard
            headers={[list_name]}
            list={hasEntries ? list.map((entry, index) => (
                <tr key={index} onClick={() => handleRowClick(entry)} style={{ cursor: 'pointer' }}>
                    <td>{entry}</td>
                </tr>
            )) : (
                <tr>
                    <td colSpan="1">No {list_name} available.</td>
                </tr>
            )}
        />
    );
};

export default ClickableListTable;

