import { Row, Col } from 'react-bootstrap';

const BreakoutRow = ({ title, text, stackTextBelow = false }) => {
    return (
        <Row className={stackTextBelow ? "flex-column" : ""}>
            <Col>
                <p className="lead fw-bold text-start text-font" style={{ whiteSpace: 'nowrap' }}>{title}</p>
            </Col>
            {stackTextBelow ? (
                <Col>
                    <p className="lead fw-small number-font">{text}</p>
                </Col>
            ) : (
                <Col>
                    <p className="lead fw-normal text-end number-font">{text}</p>
                </Col>
            )}
        </Row>
    );
};

export default BreakoutRow;
