import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { getToken } from '../../utils';
import TableCard from '../TableCard';

const apiUrl = process.env.REACT_APP_API_URL;

const ManagePortalUsers = ({ portalUsers, fetchAdminHelpers, investors }) => {
    const navigate = useNavigate();
    const [portalUserToDelete, setPortalUserToDelete] = useState({ investor_name: '' });
    const [investorToGetSecret, setInvestorToGetSecret] = useState({ investor_name: '' });
    const [loginSummary, setLoginSummary] = useState([]);

    useEffect(() => {
        const fetchLoginSummary = async () => {
            try {
                const token = getToken(navigate);
                const response = await axios.get(`${apiUrl}/admin/get_login_summary`, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                setLoginSummary(response.data);
            } catch (error) {
                console.error('Error fetching login summary:', error);
            }
        };

        fetchLoginSummary();
    }, [navigate]);

    const hasLogin = loginSummary && loginSummary.length > 0;

    const handlePortalUserToDeleteChange = (e) => {
        const { value } = e.target;
        setPortalUserToDelete({ investor_name: value });
    };

    const handleInvestorToGetSecretChange = (e) => {
        const { value } = e.target;
        setInvestorToGetSecret({ investor_name: value });
    };

    const handleDeletePortalUser = async (e) => {
        e.preventDefault();

        const { investor_name } = portalUserToDelete;
        if (!investor_name) {
            console.error('Please fill in all fields.');
            return;
        }

        try {
            const token = getToken(navigate);

            const headers = { Authorization: `Bearer ${token}` };

            await axios.post(
                `${apiUrl}/admin/delete_portal_user`,
                { investor_name },
                { headers }
            );

            alert('Portal user deleted successfully');
            fetchAdminHelpers();

            setPortalUserToDelete({ investor_name: '' });
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    const handleGetInvestorSecret = async (e) => {
        e.preventDefault();

        const { investor_name } = investorToGetSecret;
        if (!investor_name) {
            console.error('Please fill in all fields.');
            return;
        }

        try {
            const token = getToken(navigate);

            const headers = { Authorization: `Bearer ${token}` };

            const response = await axios.get(
                `${apiUrl}/admin/get_investor_secret?investor_name=${investor_name}`,
                { headers }
            );

            if (response.data && response.data.investor_secret) {
                alert(`Investor login secret is: ${response.data.investor_secret}`);
            }

            setInvestorToGetSecret({ investor_name: '' });
        } catch (error) {
            console.error('Error getting investor secret: ' + error.message);
        }
    };

    return (
        <>
            <Row className='text-font admin-row'>
                <Col md={12}>
                    <p className="lead fw-bold text-font">Delete Portal User</p>
                    <form onSubmit={handleDeletePortalUser}>
                        <div className="form-group mb-3">
                            <label className="mb-1" htmlFor="investor_name">Portal User to Delete:</label>
                            <select
                                id="investor_name"
                                name="investor_name"
                                className="form-control text-font"
                                value={portalUserToDelete.investor_name}
                                onChange={handlePortalUserToDeleteChange}
                                required
                            >
                                <option value="">Select Portal User</option>
                                {portalUsers.map((name, index) => (
                                    <option key={index} value={name}>{name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="text-center">
                            <button type="submit" className="btn btn-primary">Delete User</button>
                        </div>
                    </form>
                </Col>
            </Row>

            <Row className='text-font admin-row'>
                <Col md={12}>
                    <p className="lead fw-bold text-font">Get Investor Secret</p>
                    <form onSubmit={handleGetInvestorSecret}>
                        <div className="form-group mb-3">
                            <label className="mb-1" htmlFor="investor_name">Investor to Query:</label>
                            <select
                                id="investor_name"
                                name="investor_name"
                                className="form-control text-font"
                                value={investorToGetSecret.investor_name}
                                onChange={handleInvestorToGetSecretChange}
                                required
                            >
                                <option value="">Select Investor</option>
                                {investors.map((name, index) => (
                                    <option key={index} value={name}>{name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="text-center">
                            <button type="submit" className="btn btn-primary">Get Secret</button>
                        </div>
                    </form>
                </Col>
            </Row>
            <Row className='text-font justify-content-center'>
                <Col md={8} className="d-flex">
                    <TableCard
                        title='Login Summary'
                        headers={['Investor', 'Login Count', 'Last Login']}
                        list={hasLogin ? loginSummary.map((investor, index) => (
                            <tr key={index}>
                                <td>{investor.investor_name}</td>
                                <td>{investor.login_count}</td>
                                <td>{investor.last_login ? new Date(investor.last_login).toLocaleString() : 'N/A'}</td>
                            </tr>
                        )) : <tr><td colSpan="3">No logins available.</td></tr>}
                    />
                </Col>
            </Row>
        </>
    );
};

export default ManagePortalUsers;
