import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, DropdownButton, Dropdown, Spinner, Card, Table } from 'react-bootstrap';
import axios from 'axios';
import {
    formatCurrency, getToken, formatDate,
} from '../../utils';
import ClickableListTable from '../ClickableListTable';
import PropertyTaxes from './PropertyTaxes';
import BreakoutRow from '../BreakoutRow';
import '../AdminPage.css';

const apiUrl = process.env.REACT_APP_API_URL;

const LLCTaxes = ({ llcs, tax_years }) => {
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedTaxYear, setSelectedTaxYear] = useState('Select Tax Year:');
    const [selectedLLC, setSelectedLLC] = useState('Select LLC:');
    const [clickedProperty, setClickedProperty] = useState(null);

    useEffect(() => {
        const fetchLLCTaxes = async () => {
            if (selectedLLC !== 'Select LLC:' && selectedTaxYear !== 'Select Tax Year:') {
                setLoading(true);
                try {
                    const token = getToken(navigate);
                    const response = await axios.get(`${apiUrl}/admin/llc_taxes`, {
                        headers: { Authorization: `Bearer ${token}` },
                        params: {
                            selectedLLC,
                            selectedTaxYear
                        }
                    });

                    console.log(response.data);
                    setData(response.data);
                } catch (error) {
                    console.error('Error fetching LLC details:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        setClickedProperty(null);
        fetchLLCTaxes();
    }, [selectedTaxYear, selectedLLC, navigate]);

    const hasResponse = data
    const hasExpenses = data && data.llc_expenses && Object.keys(data.llc_expenses).length > 0;
    const hasRentalProperties = data && data.rental_income_by_member && Object.keys(data.rental_income_by_member).length > 0;
    const hasDividends = data && data.dividend_income_by_member && Object.keys(data.dividend_income_by_member).length > 0;

    const handlePropertyClick = (property) => {
        setClickedProperty(property);
        setTimeout(() => {
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        }, 150);
    };

    return (
        <>
            <Row className="d-flex align-items-stretch mb-5">
                <Col md={12} className="d-flex justify-content-center">
                    <DropdownButton className="text-font text-center" id="dropdown-basic-button" title={selectedTaxYear}>
                        {Object.values(tax_years).map(tax_year => (
                            <Dropdown.Item key={tax_year} onClick={() => setSelectedTaxYear(tax_year)}>
                                {tax_year}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                </Col>
            </Row><Row className="d-flex align-items-stretch mb-5">
                <Col md={12} className="d-flex justify-content-center">
                    <DropdownButton className="text-font text-center" id="dropdown-basic-button" title={selectedLLC}>
                        {Object.values(llcs).map(llc => (
                            <Dropdown.Item key={llc} onClick={() => setSelectedLLC(llc)}>
                                {llc}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                </Col>
            </Row>
            {loading ? (
                <Row className="d-flex justify-content-center">
                    <Spinner animation="border" variant="primary" />
                </Row>
            ) : (hasResponse && (
                <>
                    <Row className="d-flex align-items-stretch mb-4">
                        <Col md={12} className="d-flex">
                            <Card className={`mb-4 rounded-3 flex-fill text-font p-5`}>
                                <Card.Body>
                                    <BreakoutRow title="Address:" text={data.address} />
                                    <BreakoutRow title="Date Started:" text={formatDate(data.date_started)} />
                                    <BreakoutRow title="Principal Business Activity:" text={data.principal_business_activity} />
                                    <BreakoutRow title="Principal Product:" text={data.principal_product} />
                                    <BreakoutRow title="Business Code Number:" text={data.business_code_number} />
                                    <BreakoutRow title="Total LLC Assets:" text={formatCurrency(data.total_assets, false, true)} />
                                    <BreakoutRow title="Ordinary Business Income:" text={formatCurrency(data.ordinary_business_income, false, true)} />
                                    <BreakoutRow title="Total Rental Income:" text={formatCurrency(data.total_rental_income, false, true)} />
                                    <BreakoutRow title="Total Dividend Income:" text={formatCurrency(data.total_dividend_income, false, true)} />
                                    <BreakoutRow title="Total Income:" text={formatCurrency(data.total_income, false, true)} />
                                    <BreakoutRow title="Total Active Income:" text={formatCurrency(data.total_active_income, false, true)} />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="d-flex align-items-stretch mb-4">
                        <Col md={6} className="d-flex">
                            <Card className="mb-4 rounded-3 flex-fill text-font p-5">
                                <Card.Body className="d-flex flex-column gap-2">
                                    <p className="fw-bold mb-4 text-font lead">Income Summary</p>

                                    {hasRentalProperties && (
                                        <div className="table-container number-font mb-3">
                                            <p className="text-font fw-bold">Rental Income by Member</p>
                                            <Table striped hover>
                                                <thead>
                                                    <tr>
                                                        <th>Member</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Object.entries(data.rental_income_by_member).map(([member, amount], idx) => (
                                                        <tr key={idx}>
                                                            <td>{member}</td>
                                                            <td>{formatCurrency(amount, false, true)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    )}

                                    {hasDividends && (
                                        <div className="table-container number-font">
                                            <p className="text-font fw-bold">Dividend Income by Member</p>
                                            <Table striped hover>
                                                <thead>
                                                    <tr>
                                                        <th>Member</th>
                                                        <th>Income</th>
                                                        <th>Expenses</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Object.entries(data.dividend_income_by_member).map(([member, amount], idx) => (
                                                        <tr key={idx}>
                                                            <td>{member}</td>
                                                            <td>{formatCurrency(amount, false, true)}</td>
                                                            <td>{formatCurrency(data.dividend_expenses_by_member?.[member] || 0, false, true)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card className={`mb-4 rounded-3 flex-fill text-font p-5`}>
                                <Card.Body>
                                    <Card.Title className="fw-bold mb-4 text-font">Rental Income Details</Card.Title>
                                    {hasRentalProperties ? Object.entries(data.rental_income_by_member_by_property).map(([member, properties], index) => (
                                        <div key={index} className="table-container number-font">
                                            <p className="text-font fw-bold">{member} Rental Income</p>
                                            <Table striped hover>
                                                <thead>
                                                    <tr>
                                                        <th>Property</th>
                                                        <th>Income</th>
                                                        <th>UBIA</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Object.entries(properties).map(([property, income], idx) => (
                                                        <tr key={idx}>
                                                            <td>{property}</td>
                                                            <td>{formatCurrency(income, false, true)}</td>
                                                            <td>{formatCurrency(data.ubia_by_member_by_property?.[member]?.[property] || 0, false, true)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    )) : <tr><td colSpan="2">No rental properties.</td></tr>}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="d-flex align-items-stretch mb-4">
                        <Col md={6} className="d-flex">
                            <Card className={`mb-4 rounded-3 flex-fill text-font p-5`}>
                                <Card.Body>
                                    <p className="lead fw-bold">LLC Expenses</p>
                                    <div className="table-container number-font">
                                        <Table striped hover>
                                            <thead>
                                                <tr>
                                                    {['Category', 'Amount'].map((header, index) => (
                                                        <th key={index}>{header}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {hasExpenses ? Object.entries(data.llc_expenses).map(([category, amount], index) => (
                                                    <tr key={index}>
                                                        <td>{category}</td>
                                                        <td>{formatCurrency(amount, false, true)}</td>
                                                    </tr>
                                                )) : <tr><td colSpan="2">No expenses available.</td></tr>}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} className="d-flex">
                            <ClickableListTable list={data.properties_list} list_name='Properties' onRowClick={handlePropertyClick} />
                        </Col>
                    </Row>
                    {clickedProperty && (
                        <PropertyTaxes property_name={clickedProperty} selectedTaxYear={selectedTaxYear} />
                    )}
                </>
            )
            )}
        </>
    );
};

export default LLCTaxes;
