import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { getToken } from '../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

const UpdatePropertyNotes = ({ rentalProperties }) => {
    const navigate = useNavigate();
    const [selectedProperty, setSelectedProperty] = useState('');
    const [notes, setNotes] = useState('');

    useEffect(() => {
        const fetchPropertyNotes = async () => {
            if (selectedProperty) {
                try {
                    const token = getToken(navigate);

                    const response = await axios.get(`${apiUrl}/admin/get_property_notes`, {
                        headers: { Authorization: `Bearer ${token}` },
                        params: {
                            property_name: selectedProperty,
                        },
                    });

                    setNotes(response.data.notes || '');
                } catch (error) {
                    console.error('Error fetching property notes:', error);
                }
            }
        };

        fetchPropertyNotes();
    }, [selectedProperty, navigate]);

    const handlePropertyChange = (e) => {
        setSelectedProperty(e.target.value);
        setNotes('');
    };

    const handleUpdateNotes = async (e) => {
        e.preventDefault();

        if (!selectedProperty || !notes) {
            alert('Please select a property and enter notes.');
            return;
        }

        try {
            const token = getToken(navigate);

            await axios.post(`${apiUrl}/admin/set_property_notes`, {
                property_name: selectedProperty,
                notes: notes,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });

            alert('Notes updated successfully!');
        } catch (error) {
            console.error('Error updating property notes:', error);
        }
    };

    return (
        <Row className="text-font admin-row">
            <Col md={12}>
                <p className="lead fw-bold text-font">Update Property Notes</p>
                <form onSubmit={handleUpdateNotes}>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="property_name">Property:</label>
                        <select
                            id="property_name"
                            name="property_name"
                            className="form-control text-font"
                            value={selectedProperty}
                            onChange={handlePropertyChange}
                            required
                        >
                            <option value="">Select Property</option>
                            {rentalProperties.map((property_name, index) => (
                                <option key={index} value={property_name}>
                                    {property_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="notes">Notes:</label>
                        <input
                            type="text"
                            id="notes"
                            name="notes"
                            className="form-control"
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            required
                        />
                    </div>
                    <div className="text-center">
                        <button type="submit" className="btn btn-primary">
                            Update Notes
                        </button>
                    </div>
                </form>
            </Col>
        </Row>
    );
};

export default UpdatePropertyNotes;