import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { getToken } from '../../utils';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const AddInvestmentProperty = ({ llcs, propertyTypes, fetchAdminHelpers }) => {
    const navigate = useNavigate();
    const [investment_property, setInvestmentProperty] = useState({
        property_name: '',
        address: '',
        purchase_date: '',
        purchase_price: '',
        property_type: '',
        picture_url: '',
        llc: '',
        land_value: '',
        property_value: '',
    });

    const handleInvestmentPropertyChange = (e) => {
        const { name, value } = e.target;
        setInvestmentProperty({
            ...investment_property,
            [name]: value
        });
    };

    const handleInvestmentPropertySubmit = async (e) => {
        e.preventDefault();

        const { property_name, address, purchase_date, purchase_price, property_type, picture_url, llc, land_value, property_value } = investment_property;
        if (!property_name || !address || !purchase_date || !purchase_price || !property_type || !picture_url || !llc || !land_value || !property_value) {
            console.error('Please fill in all fields.');
            return;
        }

        try {
            const token = getToken(navigate);

            await axios.post(`${apiUrl}/admin/add_investment_property`,
                investment_property,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            alert(`New property added successfully`);

            fetchAdminHelpers();

            setInvestmentProperty({
                property_name: '',
                address: '',
                purchase_date: '',
                purchase_price: '',
                property_type: '',
                picture_url: '',
                llc: '',
                land_value: '',
                property_value: '',
            });
        } catch (error) {
            console.error('Error adding property:', error);
        }
    };

    return (
        <Row className='text-font admin-row'>
            <Col md={12}>
                <p className="lead fw-bold text-font">Add New Investment Property</p>
                <form onSubmit={handleInvestmentPropertySubmit}>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="property_name">Property:</label>
                        <input
                            type="text"
                            id="property_name"
                            name="property_name"
                            className="form-control"
                            value={investment_property.property_name}
                            onChange={handleInvestmentPropertyChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="address">Address:</label>
                        <input
                            type="text"
                            id="address"
                            name="address"
                            className="form-control"
                            value={investment_property.address}
                            onChange={handleInvestmentPropertyChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="purchase_date">Purchase Date:</label>
                        <input
                            type="date"
                            id="purchase_date"
                            name="purchase_date"
                            className="form-control"
                            value={investment_property.purchase_date}
                            onChange={handleInvestmentPropertyChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="purchase_price">Purchase Price:</label>
                        <input
                            type="number"
                            id="purchase_price"
                            name="purchase_price"
                            className="form-control"
                            value={investment_property.purchase_price}
                            onChange={handleInvestmentPropertyChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="land_value">Land Value:</label>
                        <input
                            type="number"
                            id="land_value"
                            name="land_value"
                            className="form-control"
                            value={investment_property.land_value}
                            onChange={handleInvestmentPropertyChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="property_value">Property Value:</label>
                        <input
                            type="number"
                            id="property_value"
                            name="property_value"
                            className="form-control"
                            value={investment_property.property_value}
                            onChange={handleInvestmentPropertyChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="llc">LLC:</label>
                        <select
                            id="llc"
                            name="llc"
                            className="form-control text-font"
                            value={investment_property.llc}
                            onChange={handleInvestmentPropertyChange}
                            required
                        >
                            <option value="">Select LLC</option>
                            {llcs.map((llc, index) => (
                                <option key={index} value={llc}>{llc}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="property_type">Type:</label>
                        <select
                            id="property_type"
                            name="property_type"
                            className="form-control text-font"
                            value={investment_property.property_type}
                            onChange={handleInvestmentPropertyChange}
                            required
                        >
                            <option value="">Select Property Type</option>
                            {propertyTypes.map((property_type, index) => (
                                <option key={index} value={property_type}>{property_type}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group mb-5">
                        <label className="mb-1" htmlFor="picture_url">Picture URL:</label>
                        <input
                            type="text"
                            id="picture_url"
                            name="picture_url"
                            className="form-control"
                            value={investment_property.picture_url}
                            onChange={handleInvestmentPropertyChange}
                            required
                        />
                    </div>
                    <div className="text-center">
                        <button type="submit" className="btn btn-primary">Add Investment Property</button>
                    </div>
                </form>
            </Col>
        </Row>
    );
};

export default AddInvestmentProperty;
