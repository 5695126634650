import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import { formatCurrency, getToken } from '../../utils';
import BreakoutRow from '../BreakoutRow';
import ClickableListTable from '../ClickableListTable';
import '../AdminPage.css';

const apiUrl = process.env.REACT_APP_API_URL;

const AdminSummary = ({ investors }) => {
    const navigate = useNavigate();
    const [totalCash, setTotalCash] = useState(0);
    const [totalInvestments, setTotalInvestments] = useState(0);
    const [totalIncome, setTotalIncome] = useState(0);
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [totalDeposits, setTotalDeposits] = useState(0);

    useEffect(() => {
        const fetchAdminSummary = async () => {
            try {
                const token = getToken(navigate);

                const response = await axios.get(`${apiUrl}/admin/get_admin_summary`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                setTotalCash(response.data.total_cash || 0);
                setTotalInvestments(response.data.total_investments || 0);
                setTotalIncome(response.data.total_income || 0);
                setTotalExpenses(response.data.total_expenses || 0);
                setTotalDeposits(response.data.total_deposits || 0);
            } catch (error) {
                console.error('Error fetching admin summary:', error);
            }
        };

        fetchAdminSummary();
    }, [navigate]);

    return (
        <>
            <Row className="d-flex align-items-stretch mb-4">
                <Col md={6} className="d-flex">
                    <Card className="p-5 mb-4 rounded-3 flex-fill">
                        <Card.Body className="d-flex flex-column justify-content-center">
                            <BreakoutRow
                                title="Total Cash"
                                text={formatCurrency(totalCash)} />
                            <BreakoutRow
                                title="Total Investments"
                                text={formatCurrency(totalInvestments)} />
                            <BreakoutRow
                                title="Total Income"
                                text={formatCurrency(totalIncome)} />
                            <BreakoutRow
                                title="Total Expenses"
                                text={formatCurrency(totalExpenses)} />
                            <BreakoutRow
                                title="Total Deposits"
                                text={formatCurrency(totalDeposits)} />
                        </Card.Body>
                    </Card>
                </Col><Col md={6} className="d-flex">
                    <ClickableListTable list={investors} list_name='Investors' path='/investor' />
                </Col>
            </Row>
        </>
    );
};

export default AdminSummary;
