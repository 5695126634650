import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import { formatCurrency, getToken } from '../../utils';
import TableCard from '../TableCard';
import BreakoutRow from '../BreakoutRow';
import '../AdminPage.css';

const apiUrl = process.env.REACT_APP_API_URL;

const PropertyTaxes = ({ property_name, selectedTaxYear }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = getToken(navigate);

                const response = await axios.get(`${apiUrl}/admin/property_taxes`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params: {
                        selectedProperty: property_name,
                        selectedTaxYear: selectedTaxYear,
                    },
                });

                setData(response.data);
            } catch (error) {
                console.error('Error fetching LLC details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [property_name, selectedTaxYear, navigate]);

    if (loading || !data) {
        return null;
    }

    return (
        <>
            <Row className="d-flex align-items-stretch mb-4">
                <Col md={12} className="d-flex">
                    <Card className={`mb-4 rounded-3 flex-fill text-font p-5`}>
                        <Card.Body>
                            <p className="text-center lead fw-bold">
                                Summary for {property_name} in {selectedTaxYear}
                            </p>
                            <BreakoutRow title="Address:" text={data.address} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="d-flex align-items-stretch mb-4">
                <Col md={6} className="d-flex">
                    <TableCard
                        title="Property Income"
                        headers={['Category', 'Amount']}
                        list={data?.income_list && Object.keys(data.income_list).length > 0 ? (
                            Object.entries(data.income_list).map(([category, amount], index) => (
                                <tr key={index} className="hover-row">
                                    <td>{category}</td>
                                    <td>{formatCurrency(amount, false, true)}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2">No income available.</td>
                            </tr>
                        )}
                    />
                </Col>
                <Col md={6} className="d-flex">
                    <TableCard
                        title="Property Expenses"
                        headers={['Category', 'Amount']}
                        list={data?.expenses_list && Object.keys(data.expenses_list).length > 0 ? (
                            Object.entries(data.expenses_list).map(([category, amount], index) => (
                                <tr key={index} className="hover-row">
                                    <td>{category}</td>
                                    <td>{formatCurrency(amount, false, true)}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2">No expenses available.</td>
                            </tr>
                        )}
                    />
                </Col>
            </Row>
        </>
    );
};

export default PropertyTaxes;
